import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "../../lib/responsive-breakpoints";
import {useEffect, useState} from "react";
import {getCategories} from "../../lib/api/menuApi";
import {CategoryEntryModel} from "../desktop-menu/models/CategoryEntryModel";
import HomeCategoryMobile from "./HomeCategoryMobile";
import HomeCategoryDesktop from "./HomeCategoryDesktop";

export interface HomeCategoryProps {
	isBlackFriday?: boolean
}

export interface HomeCategoryVariantProps {
	categories: CategoryEntryModel[]
}

export default function HomeCategory(props: HomeCategoryProps) {

	const [isLoading, setIsLoading] = useState(true);
	const isMobile = !useMediaQuery(mediaQueries.sm);
	const [categories, setCategories] = useState<CategoryEntryModel[]>();

	useEffect(() => {
		getCategories()
			.then(categoryResult => {
				setCategories(categoryResult);
				setIsLoading(false);
			});
	}, []);

	if (isLoading || !categories) {
		return (
			<div className={"tw-bg-skeletonBackgroundGrey embla-home-slider__container tw-flex tw-mb-16"}/>
		);
	}

	return isMobile ?
		<HomeCategoryMobile
			categories={categories} /> :
		<HomeCategoryDesktop
			categories={categories} />

}