import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {getBlLocaleFromString} from "lib/BlLocale";
import {BundleEntry} from "components/bundle-entry/BundleEntry";
import HomeCategory from "./components/home-category/HomeCategory";

const target = document.getElementById('home-category');
const rawLocaleCode = target?.dataset?.['localeCode'];
const localeCode = getBlLocaleFromString(rawLocaleCode);

if(target) {
	ReactDOM.render(
		<BundleEntry
			bundleName="home-category"
			locale={localeCode}
			prefetchTranslations={[
				'frontend.home.category.title',
				'frontend.home.category.beer',
				'frontend.home.category.wine',
				'frontend.home.category.spirit',
				'frontend.home.category.soft',
				'frontend.home.category.accessoires',
				'frontend.home.category.title.black.friday'
			]}
		>
			<HomeCategory/>
		</BundleEntry>,
		target
	);
}