import {useEmblaCarousel} from "embla-carousel/react";
import {HomeCategoryVariantProps} from "./HomeCategory";

export default function HomeCategoryMobile(props: HomeCategoryVariantProps) {

	const [emblaRef] = useEmblaCarousel({align: "start", containScroll: "trimSnaps", dragFree: true});

	return (
		<div>
			<div className={'embla tw--mt-8 tw--mb-3'}>
				<div className={"embla__viewport is-draggable"} ref={emblaRef}>
					<div
						className={"tw-flex tw-flex-row tw-space-x-3 tw-text-white tw-font-gotham tw-font-bold tw-font-productDetailDescription"}>
						{props.categories && props.categories.map(category =>
							<div
								key={category.title}
								className={"tw-bg-homeCategoryMobileColor tw-rounded-full tw-pl-6 tw-pr-6 tw-pt-4 tw-pb-4"}>
								<a href={category.url}
								   className={"tw-text-sliderSubTitleMobile"}
								   style={{textTransform: "capitalize", whiteSpace: "nowrap"}}>
									{category.title.toLowerCase()}
								</a>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);

}